<template>
  <mds-tabs
    variation="site-navigation"
    alignment="center"
    size="large"
    :content="tabsSiteNavContent"
    @mds-tabs-item-active.prevent="setActiveNavItemOnClick"
  />
</template>

<script>
import { MdsTabs } from '@mds/tabs';

export default {
  name: 'SiteNavigation',
  layout: 'Empty',
  components: {
    MdsTabs,
  },
  props: {
    tabsSiteNavContent: {
      type: Array,
      require: true,
      default () {
        return [];
      },
    },
  },
  mounted () {
    this.$nextTick(() => this.setActiveNavItem(this.$route.name));
  },
  methods: {
    setActiveNavItemOnClick ({ currentTarget: { id } }) {
      /** Avoids "NavigationDuplicated" error */
      if (id === this.$route.name) {
        return;
      };

      this.$router.push({ name: id });
      this.setActiveNavItem(id);
    },
    setActiveNavItem (pathName) {
      this.tabsSiteNavContent.forEach((item) => {
        item.active = (item.id === pathName);
      });
    },
  },
};
</script>
